import { IrscAbstractClass } from '../core/irsc-abstract-class';

export class IrscDeCourseComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return [
            'prefix',
            'course-number',
            'level',
            'number',
            'subject-area-requirement',
            'credit-awarded',
        ];
    }

    private data: any;
    private slotPrefix: any;
    private slotCourseNumber: any;
    private slotLevel: any;
    private slotNumber: any;
    private slotSubjectAreaRequirement: any;
    private slotCreditAwarded: any;
    private template: any;
    constructor() {
        super();
        // tslint:disable-next-line: no-dead-store
        const self: any = this;
        this.template = window.document.querySelector('#irsc-de-course-tmpl');
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        this.slotPrefix = this.shadowRoot.querySelector('slot[name="prefix"]');
        this.slotCourseNumber = this.shadowRoot.querySelector(
            'slot[name="course-number"]'
        );
        this.slotLevel = this.shadowRoot.querySelector('slot[name="level"]');
        this.slotNumber = this.shadowRoot.querySelector('slot[name="number"]');
        this.slotSubjectAreaRequirement = this.shadowRoot.querySelector(
            'slot[name="subject-area-requirement"]'
        );
        this.slotCreditAwarded = this.shadowRoot.querySelector(
            'slot[name="credit-awarded"]'
        );
    }
}
