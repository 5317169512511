import { IrscAbstractClass } from '../core/irsc-abstract-class';

export class IrscDeCoursesComponent extends IrscAbstractClass {
    static get observedAttributes() {
        return ['programs'];
    }

    public slotCourses: any;
    public courses: any;
    public deCourses: any;
    public inputFilter: any;
    public toggleFilters: any;
    private deCoursesUrl =
        'https://www.irsc.edu/_resources/data/de-courses.json';

    private template: any;
    constructor() {
        super();
        this.template = window.document.querySelector('#irsc-de-courses-tmpl');
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(this.template.content.cloneNode(true));
        this.slotCourses = this.shadowRoot.querySelector(
            'slot[name="courses"]'
        );

        this.inputFilter = this.shadowRoot.querySelector('#filter');
        this.toggleFilters = this.shadowRoot.querySelector('#search-filters');

        this.toggleFilters
            .querySelectorAll('.filter-toggle')
            .forEach((toggle: any) => {
                toggle.addEventListener('click', (e: any) =>
                    this.filterAndUpdateView()
                );
            });

        this.inputFilter.addEventListener('input', (e: any) =>
            this.filterAndUpdateView()
        );
    }

    public connectedCallback() {
        this.updateView();
    }

    public attributeChangedCallback(
        attrName: string,
        oldValue: any,
        newValue: any
    ) {
        const slot: any = this.getSlot(attrName);

        if (slot) {
            if (attrName === 'courses') {
                this.updateView();
            } else {
                slot.innerText = newValue;
            }
        }
    }

    public getSlot(name: string) {
        return this[`slot${this.titleize(this.pascalCase(name))}`];
    }

    public getProperty(name: string) {
        return this[name];
    }

    public updateView() {
        fetch(this.deCoursesUrl)
            .then((res: any) => res.json())
            .then((deCourses: any) => (this.deCourses = deCourses))
            .then((deCourses: any) => {
                this.filterAndUpdateView();
            });
    }

    // tslint:disable-next-line:cognitive-complexity
    public filterAndUpdateView() {
        const coursesContainer = this.getSlot('courses');

        const all = this.getProperty('toggleFilters').querySelector(
            '#filter-all input'
        ).checked;

        const filter = this.getProperty('inputFilter').value;

        coursesContainer.innerHTML = '';

        const _courses = this.deCourses.data.courses;
        _courses
            .map((course: any) => {
                const _filter = new RegExp(
                    this.getProperty('inputFilter').value,
                    'gim'
                );

                course.keep =
                    _filter.test(course.prefix) ||
                    _filter.test(course.CourseNumber) ||
                    _filter.test(course.number) ||
                    _filter.test(course.level) ||
                    _filter.test(
                        course.subjectAreaRequirement ||
                            _filter.test(course.creditAwarded)
                    );
                return course;
            })
            .filter((course: any) => course.keep)
            .forEach((course: any, idx: number) => {
                let inject = true;
                if (!all && idx > 9 && filter === '') {
                    inject = false;
                }
                if (inject) {
                    const dec = document.createElement('irsc-de-course');
                    dec.setAttribute('prefix', course.prefix);
                    dec.setAttribute('course-number', course.courseNumber);
                    dec.setAttribute('level', course.level);
                    dec.setAttribute('number', course.number);
                    dec.setAttribute(
                        'subject-area-requirement',
                        course.subjectAreaRequirement
                    );
                    dec.setAttribute('credit-awarded', course.creditAwarded);
                    coursesContainer.appendChild(dec);
                }
            });
    }
}
